.App {
	text-align: center;
	background-color: black;
	color: white;
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-body {
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.is-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.search-form {
	max-width: 460px;
	display: flex;
	margin: 0 auto 32px;
}

.search-form button {
	outline: none;
	border: none;
	padding: 0px 15px;
	border-radius: 0 0.35em 0.35em 0;
	cursor: pointer;
}

.active {
	background-color: #aaa;
	cursor: pointer;
}

input {
	font-size: 1em;
	width: 100%;
	background-color: white;
	padding: 10px 15px;
	border: 3px solid #aaa;
	border-right: none;
	border-radius: 0.35em 0 0 0.35em;
	outline: none;
}

.main-nav ul {
	list-style-type: none;
	padding-left: 0;
}

.main-nav li {
	width: 100%;
	margin-bottom: 1em;
}

.main-nav a {
	text-decoration: none;
	display: block;
	background: #fff;
	border-radius: 3px;
	padding: 5px;
	color: black;
}

.main-nav .active,
.main-nav a:hover {
	background-color: #aaa;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.movie-container ul {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	-ms-align-items: flex-start;
	align-items: flex-start;
}

.movie-container ul li {
	width: 220px;
	height: 165px;
	overflow: hidden;
	margin-bottom: 15px;
}

.movie-container ul img {
	width: 100%;
	transform: scale(1.25);
	transition: transform 1.25s;
}

.movie-container li:hover img {
	transform: scale(1.65);
}

.movie-container .not-found {
	width: 100%;
}

.not-found {
}

@media only screen and (min-width: 768px) {

	.container {
		max-width: 960px;
		margin: auto;
	}

	.main-nav ul {
		display: flex;
		justify-content: center;
	}

	.main-nav li {
		margin: 10px;
		width: 100px;
	}
}